var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createCollection","constants":{ environmentId: _vm.environmentId },"submitButtonText":"Crear","submitButtonIcon":"add","submit-button-color":_vm.accentColor,"schema":{
        environmentId: {
          __graphQLType: 'ID'
        },
        _id: {
          label: 'ID de colección en la base de datos',
          __graphQLType: 'ID',
          type: 'string',
          description: 'Sólo puede contener minúsculas y números.',
          fieldOptions: {
            color: _vm.accentColor,
            placeholder: 'productos',
            validatePattern: '^[a-z0-9]+$',
            validateCaseInsensitive: false,
            validateCustomError: 'Sólo puede contener minúsculas y números.'
          },
          newDesign: true,
        },
        name: {
          label: 'Nombre para identificar la colección',
          type: 'string',
          description: 'Debe ser único y reconocible.',
          fieldOptions: {
            color: _vm.accentColor,
            placeholder: 'Productos'
          },
          newDesign: true,
        }
      }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{attrs:{"slot":"left-buttons","dark":"","color":"#59a4f4"},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v("Cancelar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }